import React from "react"

import Layout from "../components/layout"
import { NavBar } from "../components/navbar"
import { PrivacyPolicy } from "../components/privacyPolicy"

const IndexPage = () => (
    <Layout>
        <div className="header-wrapper" id="home">
            <NavBar />
        </div>
        <div className="main-content">
		    <PrivacyPolicy />
        </div>
    </Layout>
)
export default IndexPage
